import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../scss/main.scss';

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            <main className="site-main" id="page-main-content">
                <div className="page-container">
                    { children }
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Layout;
