import React from 'react';

const MenuSVG = ({className}) => {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path d="M21.791 3.013h-19.583c-1.218 0-2.209 0.991-2.209 2.21 0 1.218 0.991 2.209 2.209 2.209h19.583c1.218 0 2.209-0.991 2.209-2.209 0-1.219-0.991-2.21-2.209-2.21z"></path>
            <path d="M21.791 9.791h-19.583c-1.218 0-2.209 0.991-2.209 2.21 0 1.218 0.991 2.209 2.209 2.209h19.583c1.218 0 2.209-0.991 2.209-2.209 0-1.219-0.991-2.21-2.209-2.21z"></path>
            <path d="M21.791 16.569h-19.583c-1.218 0-2.209 0.991-2.209 2.21 0 1.218 0.991 2.209 2.209 2.209h19.583c1.218 0 2.209-0.991 2.209-2.209 0-1.219-0.991-2.21-2.209-2.21z"></path>
        </svg>
    );
}

export default MenuSVG;
