import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import LogoSVG from './LogoSVG';
import PhoneSVG from './PhoneSVG';
import MenuSVG from './MenuSVG';
import CloseSVG from './CloseSVG';

const Header = () => {

    const [isActive, setIsActive] = useState(false);

    const menuButtonRef = useRef();
    const closeMenuButtonRef = useRef();
    const mainMenuRef = useRef();

    const openMainMenu = () => {
        setIsActive(true);
        closeMenuButtonRef.current.focus();
    }

    const closeMainMenu = () => {
        setIsActive(false);
        menuButtonRef.current.focus();
    }

    const closeMenuOnEscape = e => {
        if (e.key === 'Escape') {
            closeMainMenu();
        }
    }

    const closeMenuOutsideClick = e => {
        if (mainMenuRef.current && !mainMenuRef.current.contains(e.target) && isActive) {
            closeMainMenu();
        }
    }

    // useEffect(() => {
    //     document.addEventListener('keydown', closeMenuOnEscape);
    //     document.addEventListener('click', closeMenuOutsideClick);

    //     return () => {
    //         document.removeEventListener('keydown', closeMenuOnEscape);
    //         document.removeEventListener('click', closeMenuOutsideClick);
    //     }
    // }, []);

    return (
        <header className="site-header">
            <a href="#page-main-content" className="skip-content">
                Skip to Main Content
            </a>
            <div className="header-top">
                <div className="header-top__container">
                    <div className="header-information">
                        <a href="tel:+1-847-998-8670" className="header-information__phone">
                            <PhoneSVG className="header-information__svg"/>
                            847-998-8670
                        </a>
                        <span className="header-information__email">
                            glendalebuilders@sbcglobal.net
                        </span>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="header-bottom__container">
                    <div className="header-logo">
                        <Link to="/" className="header-logo__link">
                            <LogoSVG className="header-logo__svg"/>
                            <span className="visually-hidden">Home Page</span>
                        </Link>
                    </div>
                    <div className="header-menu">
                        <button className={isActive ? "header-menu__button header-menu__button--active" : "header-menu__button"} onClick={openMainMenu} ref={menuButtonRef} aria-expanded={ isActive ? "true" : "false" }>
                            <MenuSVG className="header-menu__svg" />
                            <span className="visually-hidden">
                                Main Menu
                            </span>
                        </button>
                    </div>
                    <nav className={isActive ? "header-navigation header-navigation--active" : "header-navigation"} ref={mainMenuRef}>
                        <button className="header-navigation__button" onClick={closeMainMenu} ref={closeMenuButtonRef}>
                            <CloseSVG className="header-navigation__svg" />
                        </button>
                        <ul className="header-navigation__list">
                            <li className="header-navigation__item">
                                <Link to="/" className="header-navigation__link" activeClassName="active">
                                    Home
                                </Link>
                            </li>
                            <li className="header-navigation__item">
                                <Link to="/gallery" className="header-navigation__link" activeClassName="active">
                                    Photo Gallery
                                </Link>
                            </li>
                            <li className="header-navigation__item">
                                <Link to="/about" className="header-navigation__link" activeClassName="active">
                                    About
                                </Link>
                            </li>
                            <li className="header-navigation__item">
                                <Link to="/contact" className="header-navigation__link" activeClassName="active">
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
