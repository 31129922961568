import React from 'react';
import { Link } from 'gatsby';
import PhoneSVG from './PhoneSVG';

const Footer = () => {

    const copyrightYear = new Date().getFullYear();

    return (
        <footer className="site-footer">
            <div className="footer">
                <div className="footer__container">
                    <div className="footer-top">
                        <div className="footer-top__column">
                            <h2 className="footer-top__title">
                                Where We Are Located
                            </h2>
                            <div className="footer-top__location">
                                <address className="footer-top__address">
                                    1701 East Lake Avenue, <br/>
                                    Suite 460, <br/>
                                    Glenview, IL 60025
                                </address>
                            </div>
                        </div>
                        <div className="footer-top__column">
                            <h2 className="footer-top__title">
                                How to Get In Touch
                            </h2>
                            <div className="footer-top__contact">
                                <div className="footer-top__column--sub">
                                    <h3 className="footer-top__subtitle">
                                        Call Us At
                                    </h3>
                                    <div className="footer-top__wrapper">
                                        <a href="tel:+1-847-998-8670" className="footer-top__phone">
                                            <PhoneSVG className="footer-top__svg" />
                                            847-998-8670
                                        </a>
                                    </div>
                                </div>
                                <div className="footer-top__column--sub">
                                    <h3 className="footer-top__subtitle">
                                        Send an Email
                                    </h3>
                                    <Link to="/contact" className="footer-top__email">
                                        Email Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p className="footer-bottom__copyright">
                            &copy;{copyrightYear} Glendale Builders LLC. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
