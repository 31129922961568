
import React from 'react';

const CloseSVG = ({className}) => {
    return (
        <svg viewBox="0 0 24 24" className={className}>
            <path d="M14.643 12l8.973-8.974c0.247-0.247 0.383-0.577 0.383-0.928s-0.136-0.682-0.383-0.928l-0.786-0.786c-0.247-0.248-0.577-0.383-0.929-0.383s-0.681 0.136-0.928 0.383l-8.973 8.973-8.974-8.973c-0.247-0.248-0.577-0.383-0.928-0.383s-0.681 0.136-0.928 0.383l-0.787 0.786c-0.512 0.512-0.512 1.345 0 1.856l8.973 8.974-8.973 8.973c-0.247 0.247-0.383 0.577-0.383 0.928s0.136 0.681 0.383 0.928l0.787 0.786c0.247 0.247 0.577 0.383 0.928 0.383s0.681-0.136 0.928-0.383l8.974-8.973 8.973 8.973c0.247 0.247 0.577 0.383 0.928 0.383h0c0.351 0 0.681-0.136 0.928-0.383l0.786-0.786c0.247-0.247 0.383-0.577 0.383-0.928s-0.136-0.681-0.383-0.928l-8.973-8.973z"></path>
        </svg>
    );
}

export default CloseSVG;
